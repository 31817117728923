<template>
    <div class="excel-import">
      <input type="file" ref="fileInput" class="hidden" accept=".xlsx, .xls" @change="handleClick">
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="p-5 mb-2 cursor-pointer text-center auto-suggest-suggestions-list ">
        <feather-icon icon="UploadCloudIcon" style="height:4rem !important;width:4rem !important;" svgClasses="stroke-current text-grey" /><br>
        <span> Drop Excel File or </span>
        <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">Browse</span>
        <!-- <vs-button type="border" @click.stop="$refs.fileInput.click()">Browse</vs-button> -->
      </div>
    </div>
  </template>
  
  <script>
  import XLSX from 'xlsx'
  
  export default {
    props: {
      onSuccess: {
        type: Function,
        required: true
      }
    },
    data () {
      return {
        excelData: {
          header: null,
          results: null,
          meta: null
        }
      }
    },
    methods: {
      generateData ({ header, results, meta }) {
        this.excelData.header = header
        this.excelData.results = results
        this.excelData.meta = meta
        if (this.onSuccess) this.onSuccess(this.excelData)
      },
      getHeaderRow (sheet) {
        const headers = []
        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C = undefined
        const R = range.s.r
        /* start in the first row */
        for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
          /* find the cell in the first row */
          let hdr = `UNKNOWN ${  C}` // <-- replace with your desired default
          if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
          headers.push(hdr)
        }
        return headers
      },
      handleDragover (e) {
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
      },
      handleDrop (e) {
        e.stopPropagation()
        e.preventDefault()
        const files = e.dataTransfer.files
        if (files.length !== 1) {
          this.$vs.notify({
            title: 'Login Attempt',
            text: 'Only support uploading one file!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        const rawFile = files[0] // only use files[0]
        if (!this.isExcel(rawFile)) {
          this.$vs.notify({
            title: 'Login Attempt',
            text: 'Only supports upload .xlsx, .xls, .csv suffix files',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return false
        }
        this.uploadFile(rawFile)
      },
      readerData (rawFile) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = e => {
            const data = e.target.result
            const workbook = XLSX.read(data, { type: 'array' })
            const firstSheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[firstSheetName]
            const header = this.getHeaderRow(worksheet)
            const results = XLSX.utils.sheet_to_json(worksheet)
            const meta = { sheetName: firstSheetName }
            this.generateData({ header, results, meta })
            resolve()
          }
          reader.readAsArrayBuffer(rawFile)
        })
      },
      handleClick (e) {
        const files = e.target.files
        const rawFile = files[0]
        if (!rawFile) return
        this.uploadFile(rawFile)
      },
      isExcel (file) {
        return /\.(xlsx|xls|csv)$/.test(file.name)
      },
      uploadFile (file) {
        this.$refs['fileInput'].value = null // fix can't select the same excel
        this.readerData(file)
      }
  
    }
  }
  </script>
  <style>
  .auto-suggest-suggestions-list {
        border-style: dashed !important;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: 6px;
    background-color: white;
    border-color:#ccc;
    width: 100%;
    font-size:16px;
  }
  </style>