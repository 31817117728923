<!-- =========================================================================================
    File Name: i18n.vue
    Description: I18n demo
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div>
      <div class="mb-8">
        <b-row>
          <b-col lg="8">
            <!-- <b-row>
              <b-col lg="4" v-for="photo in userData.album" :key="photo.id" @click="delete_photo( photo.id )">
                <b-img-lazy thumbnail fluid :src="photo.path"/>
                <b-button class="text-center" block size="sm" variant="flat-danger" style="font-size:11px;">刪除</b-button>
              </b-col>
            </b-row> -->
          </b-col>
          <b-col lg="4">
            <b-overlay
              :show="processing"
              spinner-variant="primary"
              spinner-type="grow"
              spinner
              rounded="sm"
            >
            <div class="excel-import">
              <input type="file" ref="fileInput" class="hidden" accept=".jpg, .jpeg" @change="handleClick">
              <div
                @click="$refs.fileInput.click()"
                @drop="handleDrop"
                @dragover="handleDragover"
                @dragenter="handleDragover"
                class="p-5 mb-2 cursor-pointer text-center auto-suggest-suggestions-list ">
                <feather-icon icon="UploadCloudIcon" style="height:4rem !important;width:4rem !important;" svgClasses="stroke-current text-grey" /><br>
                <span> Drop Photo or </span>
                <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">Browse</span>
                <!-- <vs-button type="border" @click.stop="$refs.fileInput.click()">Browse</vs-button> -->
              </div>
            </div>
          </b-overlay>
          </b-col>
    </b-row>
      </div>
      <div>
      <b-card v-if="tableData.length && header.length">
        <b-button v-if="tableData.length > 0 && is_checked === true" class="m-1" variant="primary" @click="import_now()">Submit</b-button>
        <b-table
          :items="tableData"
          :fields="header"
          hover
          responsive
        />
      </b-card>
    </div>
    </div>
  </template>
  
  <script>
  import UploadDrive from '@core/components/excel/UploadDrive.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { BLink,BImgLazy,BOverlay,BAlert,BImg,BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea,BTable, BListGroup, BListGroupItem, BFormSelect, BBadge, BRow, BCol, BCard, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton } from 'bootstrap-vue'
  export default {
    components: {
      ToastificationContent,
      UploadDrive,
      BImg,
      BImgLazy,
      BLink,
        BInputGroup, BInputGroupPrepend, BInputGroupAppend,  BFormTextarea,BAlert,
        BTable,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BFormCheckbox,
      BForm,
      BButton,
      BCard,
      BBadge,
      BListGroup,
      BOverlay,
      BListGroupItem,
      BRow,
      BCol,
    },
    props: {
      student : {
        type: String
      },
      userData : {
        type : Object
      }
    },
    data () {
      return {
        tableData: [],
        processing: false,
        header:  ['class_date', 'room_id', 'start','end','type',{key:'start_date',variant:'warning'},{key:'end_date',variant:'warning'},{key:'weekday_number',variant:'warning'},'class_name','instructor','maximum','minimum'],
        sheetName: '',
        is_checked : true
      }
    },
    methods: {
      import_now() {
        console.log(this.tableData)
         this.$http.post(process.env.VUE_APP_API_BASE+'/class/import/',{center_id : this.$store.getters['center/currentCenterId'], data : this.tableData})
          .then(res => {
            if (res.data.result === true) {
           this.tableData = []
           this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: res.data.message,
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
              })
            }
          })
      },
      loadDataInTable ({ results, header, meta }) {
        console.log(results)
      },
      generateData ({ header, results, meta }) {
        this.excelData.header = header
        this.excelData.results = results
        this.excelData.meta = meta
        if (this.onSuccess) this.onSuccess(this.excelData)
      },
      getHeaderRow (sheet) {
        const headers = []
        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C = undefined
        const R = range.s.r
        /* start in the first row */
        for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
          /* find the cell in the first row */
          let hdr = `UNKNOWN ${  C}` // <-- replace with your desired default
          if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
          headers.push(hdr)
        }
        return headers
      },
      handleDragover (e) {
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
      },
      handleDrop (e) {
        e.stopPropagation()
        e.preventDefault()
        const files = e.dataTransfer.files
        if (files.length !== 1) {
          this.$vs.notify({
            title: 'Login Attempt',
            text: 'Only support uploading one file!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        const rawFile = files[0] // only use files[0]

        this.uploadFile(rawFile)
      },
      delete_photo(photo_id) {
      this.$bvModal
        .msgBoxConfirm('確定刪除？', {
          title: '刪除作品',
          size: 'sm',
          okVariant: 'danger',
          okTitle: '確定刪除',
          cancelTitle: '取消',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$http.post(process.env.VUE_APP_API_BASE+`/student/`+ this.student +`/album/` + photo_id +'/delete/')
            .then(res => {
              this.userData.album = res.data.album
            })
          }
        })
    },
      readerData (rawFile) {
        this.processing = true
        var file = rawFile
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              console.log( reader.result )
              this.$http.post(process.env.VUE_APP_API_BASE+'/student/'+ this.student +'/album/',{ base64 : reader.result })
              .then(res => {
                if (res.data.result === true) {
                  this.userData.album = res.data.album
                  this.processing = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-center',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: res.data.message,
                    },
            })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-center',
                    props: {
                      title: 'Error',
                      icon: 'XIcon',
                      variant: 'danger',
                      text: res.data.message,
                    },
                  })
                  this.processing = false
                }
              })
            }
            reader.onerror = function (error) {
                console.log('Error: ', error)
                this.processing = false
            }  
          
      },
      handleClick (e) {
        const files = e.target.files
        const rawFile = files[0]
        if (!rawFile) return
        this.uploadFile(rawFile)
      },
      async uploadFile (file) {
        this.$refs['fileInput'].value = null // fix can't select the same excel
        this.readerData(file)
      }
  
    }
  }
  </script>