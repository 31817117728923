<template>
<b-card no-body class="card-revenue-budget">
    <b-row class="mx-0">
        <b-col md="7" class="revenue-report-wrapper">
          <b-form-input placeholder="Code / Mobile" readonly v-model="input_member" class="mb-1" v-if="manual_checkin == 'Y'"/>
          <Keypress
            key-event="keyup"
            @success="key_press"
            v-if="manual_checkin == 'Y'"
          />
            <div class="d-sm-flex justify-content-between align-items-center mb-1">
                <h4 class="card-title mb-50 mb-sm-0">
                    Sign In Information
                </h4>
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center mr-2">
                        <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"/>
                        <span>In</span>
                    </div>
                    <div class="d-flex align-items-center ml-75">
                        <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"/>
                        <span>Out</span>
                    </div>
                </div>
            </div>
            <b-button :variant="last_person.variant" block class="mb-2">{{last_person.action}}</b-button>
            <div class="d-sm-flex justify-content-between mb-1">{{last_person.datetime}}</div>
            <b-row>
                <b-col sm="4">
                    <h5>{{ $t('Code') }}:</h5>
                </b-col>
                <b-col sm="8">
                    <h5>{{last_person.code}}</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4">
                    <h5>{{ $t('Name') }}:</h5>
                </b-col>
                <b-col sm="8">
                    <h5>{{last_person.name}}</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4">
                    <h5>{{ $t('Plan Type') }}:</h5>
                </b-col>
                <b-col sm="8">
                    <h5>{{last_person.plan_type}}</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4">
                    <h5>{{ $t('Expire') }}:</h5>
                </b-col>
                <b-col sm="8">
                    <h5>{{last_person.expire}}</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4">
                    <h5>{{ $t('Status') }}:</h5>
                </b-col>
                <b-col sm="8">
                    <h5>{{last_person.status}}</h5>
                </b-col>
            </b-row>
            <b-list-group class="mt-2" v-if="last_person.today_session !== null ">
                <b-list-group-item class="d-flex" v-for="(session, index) in last_person.today_session">
                  <div v-if="session.booking_type == 'PT'">
                    <span class="mr-1" >
                    </span>
                    <span>{{session.start.substr(11,5)}}</span> - <span>{{session.end.substr(11,5)}}</span> <b>PT</b>
                  </div>
                  <div v-if="session.booking_type == 'CLASS'">
                    <span class="mr-1" >
                    </span>
                    <span>{{session.detail.start}}</span> - <span>{{session.detail.end}}</span> <b>{{session.detail.name}}</b>
                  </div>
                </b-list-group-item>
            </b-list-group>
        </b-col>

        <b-col md="5" class="budget-wrapper">

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="href()">
                Member Detail
            </b-button>
            <br>
                <img :src="last_person.photo_url" style="width:100%;border-radius:5px;"></b-col>
            </b-row>
        </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,BDropdown, BDropdownItem, BButton,BListGroup, BListGroupItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BFormInput,
    BCard,
    BButton,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    Keypress: () => import('vue-keypress')
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      input_member:'',
      member : null,
      manual_checkin : JSON.parse(localStorage.getItem('userData')).config.manual_checkin,
      last_person_data:{
        variant : "primary",
        action : " ",
      }
    }
  },
  methods: {
    key_press(r) {
      if (r.event.key == "Enter") {
        this.member_check_in(this.input_member)
        this.input_member = ''
      } else if (r.event.keyCode == 8) {
        this.input_member = this.input_member .slice(0, -1)
      } else if (r.event.keyCode >= 48 && r.event.keyCode <= 90) {
        this.input_member += r.event.key
      }
    },
    member_check_in(string) {
      this.$http.post(process.env.VUE_APP_API_BASE+`/card/` + string + `/entry/`,
      {
        key:string,
        center_id:this.$store.getters['center/currentCenterId'],
        type:"manual",
        sn:localStorage.getItem('reader'),
      }).then(res => {
        if (res.data.result === false) {
          var result =  {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              }
        } else {
           var result =  {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              }
          }
          this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: result
            })
      })
    },
    href() {
      switch (this.last_person_data.user_type) {
        case "MEMBER":
          this.$router.push(`/apps/member/edit/${this.last_person_data.id}/`)
          break;
      }
    },

  },
  computed: {
    last_person() {
      let data = this.$store.getters['center/last_person']
      data = JSON.parse(JSON.stringify(data))
      this.last_person_data = data
      return data
    },
  },
  created() {
    window.addEventListener("keydown",function(e) {
     
        if (e.keyCode > 111 && e.keyCode < 124) {
            e.preventDefault()
        }
    })
  },
}
</script>
